'use client';

import { useState, useTransition } from 'react';
import FunnelModal from './FunnelModal';
import { recordEvent } from '../actions/analytics/recordEvent';
import { useTracking } from './TrackingContext';
import Image from 'next/image';

export default function GetStartedButton({ steps, isAdmin }) {
    const [isFunnelOpen, setIsFunnelOpen] = useState(false);

    const { variant, visitId } = useTracking();

    const openFunnel = async () => {
        setIsFunnelOpen(true);
        await recordEvent({
            sessionId: localStorage.getItem('sessionId'),
            eventType: 'Get Started Button - funnel_open',
            eventData: { variant },
        })
    };
    const closeFunnel = async () => {
        setIsFunnelOpen(false);
        await recordEvent({
            sessionId: localStorage.getItem('sessionId'),
            eventType: 'Get Started Button - funnel_close',
            eventData: { variant },
        })
    };

    return (
        <>
            <button
                onClick={openFunnel}
                className="        fixed bottom-4 right-4 z-40
        flex items-center gap-4
        px-4 py-2
        bg-6ixshooter-blue
        text-white font-bold
        rounded-3xl
        shadow-md shadow-gray-600
        transition 
        duration-300 
        ease-in-out 
        transform 
        hover:bg-6ixshooter-blue-hover
        active:scale-95 
        tracking-wide"
            >
                <h2 className='text-xl'>
                Get Started Today!
                </h2>
                <Image
                    src='/6ixshooter.png'
                    alt='6ixLogo'
                    className='rounded-full bg-black p-[0.15rem]'
                    width={60}
                    height={60}
                    priority
                />
            </button>
            {isFunnelOpen && <FunnelModal variant={variant} isAdmin={isAdmin} onClose={closeFunnel} steps={steps} trainingFunnel={false} subFunnel={false} visitId={visitId} />}
        </>
    );
}