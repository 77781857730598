// components/SchedulingForm.jsx

'use client';

import { useState, useEffect } from 'react';
import { getAvailableSessions } from '@/app/actions/getAvailableSessions';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function SchedulingForm({ onSuccess, onBack }) {
    const [availableSessions, setAvailableSessions] = useState([]);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [availableTimes, setAvailableTimes] = useState([]);
    const [error, setError] = useState('');
    const [loadingSessions, setLoadingSessions] = useState(true);

    useEffect(() => {
        async function fetchSessions() {
            setLoadingSessions(true);
            const sessions = await getAvailableSessions();
            setAvailableSessions(sessions);
            setLoadingSessions(false);
        }
        fetchSessions();
    }, []);

    useEffect(() => {
        const times = availableSessions.filter(
            (session) =>
                new Date(session.dateTime).toDateString() === selectedDate.toDateString()
        );
        setAvailableTimes(times);
        setSelectedSessionId(null);
    }, [selectedDate, availableSessions]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selectedSessionId) {
            setError('Please select a time.');
            return;
        }

        // Store the selected sessionId in localStorage or a global state
        localStorage.setItem('selectedSessionId', selectedSessionId);
        onSuccess();
    };

    return (
        <form onSubmit={handleSubmit} className='text-black'>
            <h2 className="text-2xl font-bold mb-4">Select Your Training Session</h2>
            {loadingSessions ? (
                <p>Loading available sessions...</p>
            ) : (
                <>
                    <div className="mb-4">
                        <Calendar
                            onChange={setSelectedDate}
                            value={selectedDate}
                            minDate={new Date()}
                            tileDisabled={({ date, view }) =>
                                view === 'month' &&
                                !availableSessions.some(
                                    (session) =>
                                        new Date(session.dateTime).toDateString() === date.toDateString()
                                )
                            }
                            tileClassName={({ date, view }) =>
                                view === 'month' &&
                                    availableSessions.some(
                                        (session) =>
                                            new Date(session.dateTime).toDateString() === date.toDateString()
                                    )
                                    ? 'highlight'
                                    : null
                            }
                        />
                    </div>
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Available Times</h3>
                        {availableTimes.length === 0 ? (
                            <p>No available times on this date.</p>
                        ) : (
                            <ul className="space-y-2">
                                {availableTimes.map((session) => (
                                    <li key={session.id}>
                                        <label className="flex items-center space-x-2">
                                            <input
                                                type="radio"
                                                name="session"
                                                value={session.id}
                                                onChange={() => setSelectedSessionId(session.id)}
                                                className="form-radio h-4 w-4 text-blue-600"
                                            />
                                            <span className="text-gray-700">
                                                {new Date(session.dateTime).toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                            </span>
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    {error && <div className="text-red-500 mb-4">{error}</div>}
                    <div className="flex items-center">
                        <button
                            type="submit"
                                className="bg-6ixshooter-blue text-white px-4 py-2 rounded hover:bg-6ixshooter-blue-hover disabled:opacity-50"
                            disabled={!selectedSessionId}
                        >
                            Continue to Payment
                        </button>
                        {/* <button
                            type="button"
                            onClick={onBack}
                            className="ml-4 text-gray-500 hover:text-gray-700"
                        >
                            Back
                        </button> */}
                    </div>
                </>
            )}
        </form>
    );
}