// app/components/DiscordButton.jsx
import Link from 'next/link';

export default function DiscordButton() {
    return (

        <Link href="/auth/discord">
            <button className="bg-6ixshooter-blue text-white px-3 py-1 md:text-xl font-bold rounded-md hover:bg-6ixshooter-blue-hover">
                Link Discord
            </button>
        </Link>
    );
}