// components/PasswordForm.jsx

'use client';

import { useState } from 'react';
import { setPassword } from '@/app/actions/setPassword';
import { validateForm } from '@/utils/formValidation';

export default function PasswordForm({ email, onSuccess }) {
    const [password, setPasswordValue] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formError = await validateForm({ password, confirmPassword });
        if (formError.confirmPassword || formError.password) {
            console.error(formError);
            setError(formError);
            return;
        }

        try {
            await setPassword({ email, password });
            onSuccess();
        } catch (err) {
            console.error('Error setting password:', err);
            setError({ message: 'An error occurred while setting password. Please try again.' });
        }
    };

    return (
        <form onSubmit={handleSubmit} className='text-black'>
            <h2 className="text-2xl font-bold mb-4">Create Your Password</h2>
            <div className="mb-4">
                <label className="block mb-2">Password</label>
                <input
                    type="password"
                    className="w-full px-3 py-2 border rounded"
                    value={password}
                    onChange={(e) => setPasswordValue(e.target.value)}
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Confirm Password</label>
                <input
                    type="password"
                    className="w-full px-3 py-2 border rounded"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
            </div>
            {error && <div className="text-red-500 mb-4">{error.password || error.confirmPassword || error.message}</div>}
            <button
                type="submit"
                className="bg-6ixshooter-blue text-white px-4 py-2 rounded hover:bg-6ixshooter-blue-hover"
            >
                Set Password
            </button>
        </form>
    );
}