// components/SubscriptionButton.jsx

'use client';

import {useState } from 'react';
import FunnelModal from './FunnelModal';
import { recordEvent } from '../actions/analytics/recordEvent';
import { useTracking } from './TrackingContext';
import { PRODUCT_TYPES } from '@/utils/constants';
import { useSearchParams } from 'next/navigation';

export default function SubscriptionButton({ steps, loadModal }) {
    const searchParams = useSearchParams();

    let subParam = false;
    if (loadModal) {
        subParam = searchParams.get('sub');
    }



    const [isFunnelOpen, setIsFunnelOpen] = useState(subParam === 'true');
    
    const { variant, visitId } = useTracking();


    // extract just the training steps

    const subscriptionSteps = steps.filter(step => step.product.productType === PRODUCT_TYPES.SUBSCRIPTION);

    const openFunnel = async () => {
        setIsFunnelOpen(true);
        await recordEvent({
            visitId,
            eventType: 'Get Started Button - funnel_open',
            eventData: { variant },
        });
    };

    const closeFunnel = async () => {
        setIsFunnelOpen(false);
        await recordEvent({
            visitId,
            eventType: 'Get Started Button - funnel_close',
            eventData: { variant },
        });
    };

    return (
        <>
            <button
                onClick={openFunnel}
                hidden={loadModal}
                className="md:px-6 md:py-3 px-3 py-2 bg-6ixshooter-gold text-gray-800 rounded-md hover:bg-6ixshooter-gold-hover transition ease-in-out duration-300 shadow-md shadow-gray-600 active:scale-9 md:text-xl text-lg font-bold"
            >
                6ix Golden Membership 🏀🥇
            </button>
            {isFunnelOpen && <FunnelModal onClose={closeFunnel} steps={subscriptionSteps} trainingFunnel={false} subFunnel={true} visitId={visitId} />}
        </>
    );
}