// components/PaymentForm.jsx

'use client';

import { createPaymentIntent } from '@/app/actions/createPaymentIntent';
import { createSubscription } from '@/app/actions/createSubscription';
import { validateCoupon } from '@/app/actions/validateCoupon';
import { PRODUCT_TYPES } from '@/utils/constants';
import { formatPrice } from '@/utils/formatPrice';
import { validateForm } from '@/utils/formValidation';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';


export default function PaymentForm({ selectedProducts, onSuccess }) {
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);

    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState(''); // Added for confirm email
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [tosChecked, setTosChecked] = useState(false); // Terms of service required
    const [marketingChecked, setMarketingChecked] = useState(true); // Add to marketing list optional

    const courseProducts = selectedProducts.filter(p => p.productType === PRODUCT_TYPES.COURSE);
    const nonCourseProducts = selectedProducts.filter(p => p.productType !== PRODUCT_TYPES.COURSE);

    const courseTotal = courseProducts.reduce((sum, p) => sum + p.price, 0);
    const nonCourseTotal = nonCourseProducts.reduce((sum, p) => sum + p.price, 0);

    const [couponCode, setCouponCode] = useState('');
    const [couponError, setCouponError] = useState('');
    const [discountApplied, setDiscountApplied] = useState(0);
    const [appliedCoupon, setAppliedCoupon] = useState(null);

    const handleApplyCoupon = async (e) => {
        e.preventDefault();
        setCouponError('');
        if (!couponCode.trim()) {
            setCouponError('Please enter a coupon code.');
            return;
        }
        try {
            const { code, discountType, discountValue } = await validateCoupon(couponCode.trim());
            let discountedAmount = 0;
            if (courseTotal > 0) {
                if (discountType === 'PERCENTAGE') {
                    discountedAmount = courseTotal * (discountValue / 100);
                } else {
                    discountedAmount = discountValue;
                }
                if (discountedAmount > courseTotal) {
                    discountedAmount = courseTotal;
                }
                setAppliedCoupon({ code, discountType, discountValue });
                setDiscountApplied(discountedAmount);
            } else {
                setCouponError('This coupon only applies to courses, but you have no courses in your cart.');
            }
        } catch (err) {
            setCouponError(err.message);
        }
    };

    const finalAmount = (courseTotal - discountApplied) + nonCourseTotal;

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        const errors = await validateForm({ email, firstName, lastName, tosChecked });
        if (Object.keys(errors).length > 0) {
            setError(Object.values(errors).join(' '));
            return;
        }
        setProcessing(true);
        setError(null);
        try {
            const subscriptionProduct = selectedProducts.find(
                (product) => product.productType === PRODUCT_TYPES.SUBSCRIPTION
            );

            const oneTimeProducts = selectedProducts.filter(
                (product) => product.productType !== PRODUCT_TYPES.SUBSCRIPTION
            );

            let userId;
            const selectedSessionId = localStorage.getItem('selectedSessionId');
            if (subscriptionProduct) {
                const { clientSecret, subscriptionId, userId: newUserId } = await createSubscription({
                    priceId: subscriptionProduct.priceId,
                    email,
                    firstName,
                    lastName,
                    productId: subscriptionProduct.id,
                    marketing: marketingChecked
                });

                userId = newUserId;
                const result = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            email,
                            name: `${firstName} ${lastName}`,
                        },
                    },
                });

                if (result.error) {
                    setError(result.error.message);
                    setProcessing(false);
                    return;
                } else if (result.paymentIntent.status === 'succeeded') {
                    // Subscription succeeded
                }
            }

            if (oneTimeProducts.length > 0) {
                const discountCents = Math.round(discountApplied * 100);
                
                const { clientSecret } = await createPaymentIntent({
                    selectedProducts: oneTimeProducts,
                    email,
                    firstName,
                    lastName,
                    selectedSessionId,
                    couponCode: appliedCoupon?.code.toLowerCase() || '',
                    discountApplied: discountCents,
                    marketing: marketingChecked
                });

                const result = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            email,
                            name: `${firstName} ${lastName}`,
                        },
                    },
                });

                if (result.error) {
                    setError(result.error.message);
                    setProcessing(false);
                    return;
                } else if (result.paymentIntent.status === 'succeeded') {
                    onSuccess({ email });
                    localStorage.removeItem('selectedSessionId');
                }
            } else {
                // If no one-time products and subscription succeeded
                onSuccess({ email });
                localStorage.removeItem('selectedSessionId');
            }
        } catch (err) {
            console.error('Payment failed:', err);
            setError('Payment failed. Please try again.');
        } finally {
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className='text-black'>
            <div className="mb-4">
                <label className="block mb-2">Email</label>
                <input
                    type="email"
                    className="w-full px-3 py-2 border rounded"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Confirm Email</label>
                <input
                    type="email"
                    className="w-full px-3 py-2 border rounded"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">First Name</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border rounded"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Last Name</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border rounded"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2">Discount Code (Course Only)</label>
                <div className="flex items-center">
                    <input
                        type="text"
                        className="w-full px-3 py-2 border rounded mr-2"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <button onClick={handleApplyCoupon} className="bg-6ixshooter-blue text-white px-4 py-2 rounded hover:bg-6ixshooter-blue-hover">
                        Apply
                    </button>
                </div>
                {couponError && <div className="text-red-500 mb-2">{couponError}</div>}
                {appliedCoupon && (
                    <p className="text-green-500">
                        Coupon &quot;{appliedCoupon.code}&quot; applied!
                    </p>
                )}
            </div>

            <div className="mb-4">
                <label className="block mb-2">Card Details</label>
                <CardElement options={{ hidePostalCode: true }} />
            </div>

            <div className="mb-4">
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={tosChecked}
                        onChange={(e) => setTosChecked(e.target.checked)}
                        required
                    />
                    <span className="ml-2">I agree to the Terms of Service (required)</span>
                </label>
            </div>

            <div className="mb-4">
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={marketingChecked}
                        onChange={(e) => setMarketingChecked(e.target.checked)}
                    />
                    <span className="ml-2">Add me to the Marketing List (optional)</span>
                </label>
            </div>

            {error && <div className="text-red-500 mb-4">{error}</div>}
            <button
                type="submit"
                disabled={!stripe || processing}
                className="bg-6ixshooter-green text-white px-4 py-2 rounded hover:bg-6ixshooter-green-hover"
            >
                {processing ? 'Processing...' : `Pay ${formatPrice(finalAmount)}`}
            </button>
        </form>
    );
}