// components/product-templates/TrainingTemplateA.jsx

export default function TrainingTemplateA({
    product,
    onAddProduct,
    onPrevious,
    currentStep,
    totalSteps,
    onSkipProduct,
    onNext,
    trainingFunnel,
    subFunnel,
}) {
    return (
        <div className="rounded-2xl ">
            <div className="bg-white shadow-xl rounded-lg w-full max-w-lg p-7 mb-4">
    
                <h2 className="text-3xl font-semibold text-center text-gray-700 mb-4">
                    Ultimate Shooting Masterclass
                </h2>
                <p className="text-2xl text-center font-bold text-6ixshooter-green">${product.price.toFixed(2)}</p>
                <hr className='my-4 border-indigo-200' />
                <div className="text-6ixshooter-bg-black text-base mb-6 text-left">
                    <h3 className="text-xl font-bold mb-3">
                        Elevate Your Skills with Elite Instructors
                    </h3>
                    <ul className="space-y-4">
                        <li className="flex items-center">
                            🥅 <span className="ml-2 font-semibold">1-Hour Comprehensive Training</span> — Intensive sessions to refine your techniques.
                        </li>
                        <li className="flex items-center">
                            📅 <span className="ml-2 font-semibold">Flexible Scheduling Options</span> — Choose times that fit your lifestyle.
                        </li>
                        <li className="flex items-center">
                            🥇 <span className="ml-2 font-semibold">Exclusive Performance Reports</span> — Detailed analysis to track your progress.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="flex justify-between">
                {trainingFunnel || subFunnel ? (
                    <button
                        onClick={onAddProduct}
                        className="bg-6ixshooter-blue text-white px-2 py-2 rounded shadow-md hover:bg-6ixshooter-blue-hover"
                    >
                        Next
                    </button>
                ) : (
                    <>
                        <button
                            onClick={onPrevious}
                            disabled={currentStep === 0}
                            className="bg-gray-300 text-gray-700 px-2 py-2 rounded disabled:opacity-50"
                        >
                            Previous
                        </button>
                        <div className="space-x-4">
                            <button
                                onClick={onSkipProduct}
                                className="bg-gray-300 text-gray-700 px-2 py-2 rounded"
                            >
                                No thanks
                            </button>
                            <button
                                onClick={onAddProduct}
                                className="bg-6ixshooter-blue text-white px-2 py-2 rounded shadow-md hover:bg-6ixshooter-blue-hover "
                            >
                                Add to Cart
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}