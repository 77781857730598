// components/product-templates/DefaultProductTemplate.jsx

export default function DefaultProductTemplate({
    product,
    onAddProduct,
    onPrevious,
    currentStep,
    totalSteps,
    onSkipProduct,
    trainingFunnel,
    subFunnel,
}) {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">{product.name}</h2>
            <p className="mb-4">{product.description}</p>
            <p className="text-xl font-bold mb-6">${product.price.toFixed(2)}</p>
            {/* Conditional content based on trainingFunnel */}
            <div className="flex justify-between">
                {trainingFunnel || subFunnel ? (
                    <button
                        onClick={onAddProduct}
                        className="bg-blue-500 text-white px-2 py-2 rounded hover:bg-blue-600"
                    >
                        Next
                    </button>
                ) : (
                    <>
                        <button
                            onClick={onPrevious}
                            disabled={currentStep === 0}
                            className="bg-gray-300 text-gray-700 px-2 py-2 rounded disabled:opacity-50"
                        >
                            Previous
                        </button>
                        <div className="space-x-4">
                            <button
                                onClick={onSkipProduct}
                                className="bg-gray-300 text-gray-700 px-2 py-2 rounded"
                            >
                                No thanks
                            </button>
                            <button
                                onClick={onAddProduct}
                                className="bg-blue-500 text-white px-2 py-2 rounded hover:bg-blue-600"
                            >
                                Add to Cart
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}