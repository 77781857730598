// components/product-templates/CourseTemplateB.jsx
'use client'
import Image from "next/image";
import { useEffect, useState } from "react";

export default function CourseTemplateB({
    product,
    onAddProduct,
    onPrevious,
    currentStep,
    totalSteps,
    onSkipProduct,
    onNext,
}) {
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        // Set countdown timer (e.g., 24 hours from component mount)
        const deadline = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours
        const timer = setInterval(() => {
            const now = new Date().getTime();
            const distance = deadline - now;
            if (distance < 0) {
                clearInterval(timer);
                setTimeLeft(0);
            } else {
                setTimeLeft(distance);
            }
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor((totalSeconds % (3600)) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
    };
    return (
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            {/* Header Section */}
            <div className="p-2 sm:p-4">
                <h2 className="text-2xl sm:text-4xl font-extrabold text-center text-gray-800 mb-4">
                    Elevate Your Game with 6ixShooter Academy
                </h2>
                <h3 className="mb-2  text-xl sm:text-xl font-semibold text-red-600 text-center">
                    Limited Time Offer: 50% Off with Code &quot;6IX&quot;!
                </h3>
            </div>

            {/* Countdown Timer */}
                <div className="flex justify-center">
                    <div className="bg-red-500 text-white px-4 py-2 rounded-full text-sm sm:text-base font-semibold">
                        Offer Ends In: {timeLeft > 0 ? formatTime(timeLeft) : 'Offer Ended'}
                    </div>
                </div>

            {/* Scrollable Content Area */}
            <div className="relative rounded-xl shadow-sm border-2 border-6ixshooter-blue transition-transform duration-300 max-h-96 m-6 overflow-y-auto">
                <div className="flex flex-col items-center p-6 sm:p-8 lg:p-10 max-w-3xl mx-auto">
                    {/* Social Proof */}

                    {/* Features List */}
                    <div className="mb-6 w-full">
                        <h3 className="text-xl sm:text-3xl font-bold text-center text-gray-800 mb-4">
                            Why Choose 6ixShooter Academy?
                        </h3>
                        <ul className="space-y-4 sm:space-y-5">
                            <li className="flex items-start">
                                <svg
                                    className="h-6 w-6 text-green-500 flex-shrink-0 mr-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                                <div>
                                    <h4 className="text-lg sm:text-xl font-semibold text-gray-800">Comprehensive Training Guides</h4>
                                    <p className="text-gray-600">
                                        Access over 65 detailed guides covering every aspect of elite shooting and gameplay.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start">
                                <svg
                                    className="h-6 w-6 text-green-500 flex-shrink-0 mr-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                </svg>
                                <div>
                                    <h4 className="text-lg sm:text-xl font-semibold text-gray-800">Exclusive Workouts</h4>
                                    <p className="text-gray-600">
                                        Unlock workouts designed by 6ixShooter to build shooting, agility, and endurance.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start">
                                <svg
                                    className="h-6 w-6 text-green-500 flex-shrink-0 mr-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h16M8 12h16M8 17h16" />
                                </svg>
                                <div>
                                    <h4 className="text-lg sm:text-xl font-semibold text-gray-800">Complete Shooting Program</h4>
                                    <p className="text-gray-600">
                                        Enhance your physical capabilities with our structured shooting training regimen.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start">
                                <svg
                                    className="h-6 w-6 text-green-500 flex-shrink-0 mr-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                                <div>
                                    <h4 className="text-lg sm:text-xl font-semibold text-gray-800">Tailored for All Levels</h4>
                                    <p className="text-gray-600">
                                        Whether you&apos;re just starting or aiming for advanced mastery, our courses adapt to your needs.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    {/* Product Image */}
                    <div className="flex justify-center mb-6">
                        <Image
                            width={400}
                            height={500}
                            src={product.image || "/course_photos/image-3.jpeg"}
                            alt={product.name}
                            className="shadow-lg rounded-xl"
                        />
                    </div>
                </div>
            </div>

            {/* Call-to-Action Button */}
            <div className="mt-8 mb-4 sm:mt-12 m-2 flex justify-center">
                <button
                    onClick={onNext}
                    className={`w-full sm:w-2/3 px-6 py-3 bg-green-500 hover:bg-green-600
                        text-white font-bold rounded-full shadow-lg 
                        transition
                        duration-300
                        ease-in-out
                        transform
                        active:scale-95
                        focus:outline-none flex items-center justify-center
                    `}
                >
                    {/* Next Icon */}
                    <span className="mr-2 text-base sm:text-lg">Get Started Now</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 sm:h-6 sm:w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 7l5 5-5 5m5-5H6"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
}